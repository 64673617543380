import React from 'react';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { SliceHeader } from './Page/SliceHeader';
import { Tile } from './Tile';
import Section from './Section';
import Button from './Button';
import * as styles from './TileGrid.module.scss';
import { GET_ALL_DEALS } from './TileGridQueries';
import { getCookie } from '../apis';

export const TileGrid = props => {
  const {
    title,
    tagline,
    cta,
    tiles,
    warningStatements,
    ctaText,
    backLink,
    setView,
    inheritedClassnames,
    isLandingPage,
    isAllBlogPosts,
  } = props;

  // const { resource: user, loading: userLoading } = useContext(AuthContext) || {};

  const { data: allDealsNode, loading: allDealsLoading } = useQuery(GET_ALL_DEALS, {
    variables: {
      searchMetaInput: {
        perPage: 'FIFTY',
        page: 0,
        order: 'DESC',
      },
      searchDealInput: {
        statusId: 'ACTIVE',
        expiresAfter: dayjs().format('YYYY-MM-DD'),
      },
    },
  });

  // const isSophisticatedUser = user?.profile?.sophisticatedStatus?.name === 'Confirmed';
  const allDealsData = allDealsNode?.allDeals;
  const allCookies = getCookie();

  // Retrieve prismic ids from cookies set when a user visits a private deal room
  const visitedOfferPrismicIds = Object.keys(allCookies || {})
    .filter(key => key.includes('eq_deal_offer'))
    .map(key => allCookies[key]);

  // Filter ids for any deal that already has a tile using prismic id
  const filteredIds = visitedOfferPrismicIds.filter(
    prismicId => !tiles?.some(tile => tile?.tile?.document?.prismicId === prismicId || !tile?.tile?.document?.prismicId)
  );

  // Filter the database deals with the filtered prismic ids
  const filteredDbDeals =
    filteredIds.length > 0 && allDealsData ? allDealsData.filter(deal => filteredIds.includes(deal?.prismicId)) : [];

  // If user is not a sophisticated user, only show retail deals
  // if (!isSophisticatedUser) {
  //   filteredDbDeals.filter(deal => deal?.type.id === 1);
  // }

  // For each filtered deal, create an object and push to front of tiles array
  if (filteredDbDeals?.length > 0) {
    filteredDbDeals?.forEach(deal => {
      const {
        country,
        name: companyName,
        prismicId,
        prismicUid: uid,
        expiresAt,
        target,
        targetMax,
        logoUrl,
        imageUrl,
        type,
        description,
        isPrivate,
        key,
      } = deal;
      const duplicateTiles = tiles.filter(tile => tile?.tile?.document?.data?.company_name?.text === companyName);
      duplicateTiles.forEach(tile => {
        const indexToRemove = tiles.indexOf(tile);
        if (indexToRemove !== -1) {
          tiles.splice(indexToRemove, 1);
        }
      });
      const visibility = !isPrivate ? 'Public' : 'Private';
      let offerType;
      switch (type?.id) {
        case 1:
          offerType = 'Retail';
          break;
        case 2:
          offerType = 'IPO';
          break;
        case 3:
          offerType = 'Wholesale';
          break;
        case 4:
          offerType = 'ECF';
          break;
        default:
          break;
      }

      // In below object, hide_raised_amount set to true to hide metrics
      // Added hide_eoi_tag to hide the eoi tag and show description
      const dbDealToPrismicObject = {
        cta: 'Link',
        tile: {
          document: {
            id: key,
            prismicId,
            type: 'deal_room',
            uid,
            data: {
              company_name: { text: companyName },
              country: country?.name,
              end_date: expiresAt,
              eoi_footer: { html: '' },
              hide_raised_amount: true,
              hide_eoi_tag: true,
              hide_timer: false,
              is_maxed_out: false,
              is_eoi: false,
              offer_type: offerType,
              round_maximum: targetMax,
              round_minimum: target,
              tile_description: { text: description },
              tile_footer: { text: '' },
              tile_image: {
                alt: null,
                type: 'private',
                localFile: null,
                url: imageUrl,
              },
              tile_logo: { alt: null, localFile: null, url: logoUrl },
              visibility,
            },
          },
        },
      };
      tiles.unshift(dbDealToPrismicObject);
    });
  }

  const hasTiles = tiles?.length > 0;

  const validClassnames = inheritedClassnames || {};

  return (
    <Section
      className={`${styles.dealGridContainer} ${validClassnames.dealGridContainer}`}
      noContainer
      sliceName="Tiles"
    >
      {hasTiles && (
        <>
          <SliceHeader title={title} tagline={tagline} titleName={validClassnames.pageSliceTitle} />
          {cta?.url && <Button to={cta?.url}>{ctaText?.text}</Button>}
          {backLink && (
            <p className={styles.backToResultsButton} onClick={() => setView('all')}>
              Back to search results
            </p>
          )}
          <div className={`${styles.dealGrid} ${validClassnames.dealGrid}`}>
            {tiles?.map(tile => (
              <Tile
                {...tile}
                isLandingPage={isLandingPage}
                isAllBlogPosts={isAllBlogPosts}
                warningStatements={warningStatements}
                dealTileLocation="Grid"
                key={Math.random()}
              />
            ))}
          </div>
        </>
      )}
    </Section>
  );
};
