// extracted by mini-css-extract-plugin
export var active = "RegisterInterestForm-module--active--c6sUr";
export var button = "RegisterInterestForm-module--button--o2TWB";
export var checkboxContainer = "RegisterInterestForm-module--checkbox-container--2EW8T";
export var checkboxLabel = "RegisterInterestForm-module--checkbox-label--1Im3L";
export var eoiFormIcon = "RegisterInterestForm-module--eoi-form-icon--v7wn2";
export var eoiSubmitted = "RegisterInterestForm-module--eoi-submitted--ILsNn";
export var field = "RegisterInterestForm-module--field--+xvCN";
export var form = "RegisterInterestForm-module--form--jhy0L";
export var formOneContainer = "RegisterInterestForm-module--formOneContainer--n1ZB9";
export var formTwoContainer = "RegisterInterestForm-module--formTwo-container--CfP+k";
export var icon = "RegisterInterestForm-module--icon--eJrS1";
export var modal = "RegisterInterestForm-module--modal--QHpMa";
export var modalContent = "RegisterInterestForm-module--modal-content--fuzpJ";
export var modalContentBody = "RegisterInterestForm-module--modal-content-body--7b1Qp";
export var modalFooterText = "RegisterInterestForm-module--modal-footer-text--Fxy5y";
export var modelSocialShare = "RegisterInterestForm-module--modelSocialShare--escln";
export var primary = "#06004d";
export var registerInterestForm = "RegisterInterestForm-module--register-interest-form--O624l";
export var registerInterestFormInputsContainer = "RegisterInterestForm-module--register-interest-form-inputs-container--Q0V8w";
export var registerInterestFormLinkText = "RegisterInterestForm-module--register-interest-form-link-text--hB3ef";
export var registerInterestFormLogo = "RegisterInterestForm-module--register-interest-form-logo--KBDta";
export var registerInterestFormTitle = "RegisterInterestForm-module--register-interest-form-title--AVLmS";
export var registerInterestFormTitleContainer = "RegisterInterestForm-module--register-interest-form-title-container--ZU-Xe";
export var registerInterestModal = "RegisterInterestForm-module--register-interest-modal--Yc8+a";
export var registerTitle = "RegisterInterestForm-module--register-title--f3Z6-";
export var retailMaxProfileLink = "RegisterInterestForm-module--retail-max-profile-link--UQLzH";
export var secondary = "#4dafd7";
export var syndicateFormOneContainer = "RegisterInterestForm-module--syndicateFormOneContainer--60Yyv";
export var validationError = "RegisterInterestForm-module--validation-error--8qa2W";