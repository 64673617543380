import { gql } from '@apollo/client';

export const GET_ALL_DEALS = gql`
  query allDeals($searchMetaInput: SearchMetaInput!, $searchDealInput: SearchDealInput) {
    allDeals(searchMetaInput: $searchMetaInput, searchDealInput: $searchDealInput) {
      id
      key
      name
      description
      valuation
      imageUrl
      target
      prismicUid
      prismicId
      investmentMin
      investmentIncrement
      logoUrl
      hideRaisedAmount
      investmentTotals {
        total
      }
      metrics {
        numInvestments
        isLive
      }
      country {
        id
        name
      }
      sharePrice
      targetMax

      isPrivate
      type {
        id
      }
      expiresAt
      status {
        id
      }
    }
  }
`;
