import React, { useState, useContext } from 'react';
import { FormikForm, Link, Image } from 'components';
import { CheckBoxSingleInput, SelectInput, TempPhoneInput, TextInput } from './FormikInputs';
import { DASHBOARD } from '../apis/routes';
import {
  trackEoiSubscribed,
  trackAmplitudeEvent,
  pushDataLayerEvent,
  createOrUpdateAutopilotUser,
  trackOrtto,
} from '../utils/analytics';
import { AuthContext, GeoContext, TrackEventContext, UtmContext } from '../context';
import { shareLinks } from '../utils';
import * as styles from './RegisterInterestForm.module.scss';
import { SocialLinks } from './SocialLinks';
import { ALL_COMMS } from '../utils/constants/subscription';

// This component is used in the landing pages, dealroom register interest component is different
export const RegisterInterestForm = props => {
  const {
    companyName,
    isEoi,
    logo,
    metrics,
    prismicId,
    registerInterestFooter,
    uid,
    customStyle,
    linkToOffer,
    isSyndicateDeal,
    isWholesaleDeal,
  } = props || {};

  const [step, setStep] = useState('one');

  const [oneVals, setOneVals] = useState();

  const raiseStatus = isEoi ? 'pre raise' : 'post raise';

  const regShareLinks = shareLinks(uid, companyName);

  const customFormHeaderColors = {
    backgroundColor: customStyle?.primary,
  };

  return (
    <div className={styles.registerInterestForm}>
      <div style={customFormHeaderColors} className={styles.registerInterestFormTitleContainer}>
        {logo?.url ? (
          <div className={styles.registerInterestFormLogo}>
            <Image image={logo} alt={logo?.alt} />
          </div>
        ) : null}
        <h3 className={styles.registerInterestFormTitle}>REGISTER YOUR INTEREST</h3>
      </div>
      <div className={styles.registerInterestFormInputsContainer}>
        {step === 'one' ? (
          <FirstStep
            isEoi={isEoi}
            metrics={metrics}
            companyName={companyName}
            setOneVals={setOneVals}
            setStep={setStep}
            raiseStatus={raiseStatus}
            prismicId={prismicId}
            linkToOffer={linkToOffer}
            uid={uid}
            customStyle={customStyle}
            isSyndicateDeal={isSyndicateDeal}
            isWholesaleDeal={isWholesaleDeal}
          />
        ) : (
          <SecondStep
            links={regShareLinks}
            companyName={companyName}
            isEoi={isEoi}
            metrics={metrics}
            oneVals={oneVals}
            prismicId={prismicId}
            registerInterestFooter={registerInterestFooter}
            uid={uid}
            raiseStatus={raiseStatus}
            linkToOffer={linkToOffer}
            customStyle={customStyle}
            isSyndicateDeal={isSyndicateDeal}
            isWholesaleDeal={isWholesaleDeal}
          />
        )}
        {linkToOffer?.url && (
          <Link key={linkToOffer.url} to={linkToOffer.url}>
            <p className={styles.registerInterestFormLinkText}>Click here to find out more</p>
          </Link>
        )}
      </div>
    </div>
  );
};

const FirstStep = props => {
  const {
    isEoi,
    metrics,
    companyName,
    setOneVals,
    setStep,
    raiseStatus,
    uid,
    customStyle,
    isSyndicateDeal,
    isWholesaleDeal,
  } = props;
  const { hasMaxxedNzRetailFunding, hasTargetMax, isLive } = metrics || {};
  const { trackIntercomEvent } = useContext(TrackEventContext) || {};

  const { countryName: countryOfResidence } = useContext(GeoContext) || {};

  const { resource: user } = useContext(AuthContext) || {};
  const { address, firstName: first, lastName: last, email, profile } = user || {};

  const { utmParams } = useContext(UtmContext) || {};
  const { utmSource, utmMedium, utmCampaign } = utmParams || {};

  const formikClassnames = {
    validationError: styles.validationError,
    active: styles.active,
    form: styles.form,
    checkboxContainer: styles.checkboxContainer,
    button: styles.button,
  };

  const validationFunction = values => {
    const { firstName, lastName, email: emailToValidate } = values;
    const stripEmailtoValidate = emailToValidate.trim();

    if (!firstName) return { firstName: 'Please provide your first name' };

    if (!lastName) return { lastName: 'Please provide your last name' };

    if (!emailToValidate) return { email: 'Please provide your email' };

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(stripEmailtoValidate)) {
      return { email: 'Please check email you have entered is valid' };
    }
  };

  const onSubmit = values => {
    const trimmedEmail = values?.email.trim();

    try {
      try {
        trackIntercomEvent({
          email: trimmedEmail,
          name: `${values?.firstName} ${values?.lastName}`,
          customAttributes: {
            country: countryOfResidence || '',
            optOutNewsletter: !values?.subscribeToComms,
            firstName: values?.firstName,
            lastName: values?.lastName,
          },
          eventName: 'EOI Subscribed',
          metadata: {
            offerName: companyName,
            source: utmSource,
            medium: utmMedium,
            campaign: utmCampaign,
            raiseStatus,
            pageSource: 'Landing Page',
            landingPageUid: uid,
            isSyndicateDeal,
          },
        });
      } catch (error) {
        console.error('Intercom event EOI Subscribed failed', error);
      }

      try {
        trackAmplitudeEvent({
          email: trimmedEmail,
          eventName: 'EOI Subscribed',
          eventData: {
            offerName: companyName,
            raiseStatus,
            pageSource: 'Landing Page',
            landingPageUid: uid,
            uid,
            isSyndicateDeal,
          },
          userData: {
            country: countryOfResidence || '',
            state: address?.state,
            email: trimmedEmail,
            subscribeToEmails: values?.subscribeToComms,
            firstName: values?.firstName,
            lastName: values?.lastName,
            sophisticatedStatus: profile?.sophisticatedStatus?.name,
            source: utmSource,
            medium: utmMedium,
            campaign: utmCampaign,
            telephone: profile?.telephone,
            identityVerifiedAt: user?.identityVerifiedAt,
          },
        });
      } catch (error) {
        console.error('Amplitude event EOI Subscribed failed', error);
      }

      if (isSyndicateDeal) {
        try {
          trackAmplitudeEvent({
            email: trimmedEmail,
            eventName: 'Sydicate Deal EOI Subscribed',
            eventData: {
              offerName: companyName,
              raiseStatus,
              pageSource: 'Landing Page',
              landingPageUid: uid,
              uid,
              isSyndicateDeal,
            },
            userData: {
              country: countryOfResidence || '',
              state: address?.state,
              email: trimmedEmail,
              subscribeToEmails: values?.subscribeToComms,
              firstName: values?.firstName,
              lastName: values?.lastName,
              sophisticatedStatus: profile?.sophisticatedStatus?.name,
              source: utmSource,
              medium: utmMedium,
              campaign: utmCampaign,
              telephone: profile?.telephone,
              identityVerifiedAt: user?.identityVerifiedAt,
            },
          });
        } catch (error) {
          console.error('Amplitude event EOI Subscribed failed', error);
        }

        try {
          trackIntercomEvent({
            email: trimmedEmail,
            name: `${values?.firstName} ${values?.lastName}`,
            customAttributes: {
              country: countryOfResidence || '',
              optOutNewsletter: !values?.subscribeToComms,
              firstName: values?.firstName,
              lastName: values?.lastName,
            },
            eventName: 'Sydicate Deal EOI Subscribed',
            metadata: {
              offerName: `${companyName} ${uid}`,
              source: utmSource,
              medium: utmMedium,
              campaign: utmCampaign,
              raiseStatus,
              pageSource: 'Landing Page',
              landingPageUid: uid,
              isSyndicateDeal,
            },
          });
        } catch (error) {
          console.error('Intercom event EOI Subscribed failed', error);
        }
      }

      try {
        createOrUpdateAutopilotUser({
          email: trimmedEmail,
          firstName: values?.firstName,
          lastName: values?.lastName,
          optOutNewsletter: !values?.subscribeToComms,
          listName: isEoi ? `${companyName} ${uid} EOI List` : `Post-Raise ${companyName} ${uid} EOI List`,
        });
      } catch (error) {
        console.error(error);
      }

      try {
        trackOrtto({
          email: trimmedEmail,
          companyName,
          firstName: values?.firstName,
          lastName: values?.lastName,
          tags: [`EOI PAGE 1 - ${companyName}`],
          subscribeToEmails: values?.subscribeToComms,
          eventName: 'eoisubscribedpg1',
          utmSource,
          utmMedium,
          utmCampaign,
          uid,
          raiseStatus,
          isSyndicateDeal,
          subscriptionStatus: values?.subscribeToComms ? ALL_COMMS : null,
        });
      } catch (error) {
        console.error('Ortto contact update - Page 1 failed', error);
      }

      try {
        pushDataLayerEvent({ eventName: 'Clicked Register', eventData: { offerName: companyName } });
      } catch (pushDataLayerRegisterFailed) {
        console.error('Data layer event Register failed', {
          error: pushDataLayerRegisterFailed,
          offer: companyName,
        });
      }
    } catch (eoiSubscribedModalIntercomError) {
      console.error('Event EOI subscribed failed', {
        error: eoiSubscribedModalIntercomError,
        offer: companyName,
      });
    }
    setOneVals(values);
    return setStep('two');
  };

  const showExpiredCopy = !isLive && !hasTargetMax && !isEoi;
  // Show modal to NZ investors when NZ retail deal has hit it's retail cap but funding still available to wholesale investors
  const showRetailMaxCopy = hasMaxxedNzRetailFunding && isLive && !hasTargetMax;

  return (
    <div className={`${styles.formOneContainer} ${isSyndicateDeal && styles.syndicateFormOneContainer}`}>
      {/* {showRetailMaxCopy && <RetailMaxCopy />} */}
      {/* {hasTargetMax && <TargetMaxCopy />} */}
      {/* {showExpiredCopy && <DealExpiredCopy />} */}
      <FormikForm
        initialValues={{
          firstName: first || '',
          lastName: last || '',
          email: email || '',
          subscribeToComms: true,
        }}
        validationFunction={validationFunction}
        onSubmit={onSubmit}
        submitButtonTheme="highlight"
        submitButtonText="Register Interest"
        formikClassnames={formikClassnames}
        customStyle={customStyle}
      >
        <TextInput name="firstName" label="First Name" fieldclassname="split" formikClassnames={formikClassnames} />
        <TextInput name="lastName" label="Last Name" fieldclassname="split" formikClassnames={formikClassnames} />
        <TextInput name="email" label="Email" formikClassnames={formikClassnames} />
        <CheckBoxSingleInput formikClassnames={formikClassnames} name="subscribeToComms">
          Inform me about news and upcoming deals
        </CheckBoxSingleInput>
      </FormikForm>
      {!isWholesaleDeal && (
        <div className={styles.modalFooterText}>
          <p>You should consider the CSF offer document and the general CSF risk warning.</p>
        </div>
      )}
    </div>
  );
};

const SecondStep = props => {
  const {
    companyName,
    isEoi,
    metrics,
    oneVals,
    prismicId,
    registerInterestFooter,
    uid,
    links,
    raiseStatus,
    customStyle,
    isSyndicateDeal,
    isWholesaleDeal,
  } = props;
  const { hasTargetMax } = metrics || {};
  const { firstName, lastName, email, subscribeToComms } = oneVals;

  const { trackIntercomEvent } = useContext(TrackEventContext);

  const { countryOfResidence } = useContext(GeoContext);

  const { utmParams } = useContext(UtmContext) || {};
  const { utmSource, utmMedium, utmCampaign } = utmParams || {};

  const { user } = useContext(AuthContext);
  const { address, id: userId, phone: userPhone, profile } = user || {};
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [displayFounderContactOption, setDisplayFounderContactOption] = useState(false);

  const formikClassnames = {
    validationError: styles.validationError,
    active: styles.active,
    form: styles.form,
    checkboxContainer: styles.checkboxContainer,
    button: styles.button,
  };

  const validationFunction = values => {
    const { amount, phone } = values;

    if (!isSyndicateDeal) {
      switch (amount) {
        case '$5,001 - $10,000':
          setDisplayFounderContactOption(true);
          break;
        case '$10,001 - $25,000':
          setDisplayFounderContactOption(true);
          break;
        case 'more than $25,000':
          setDisplayFounderContactOption(true);
          break;
        default:
          setDisplayFounderContactOption(false);
      }

      if (amount === 'more than $25,000' && !phone) return { phone: 'Please enter a phone number' };
    }

    if (isSyndicateDeal) {
      switch (amount) {
        case 'up to $5,000':
          setDisplayFounderContactOption(true);
          break;
        case '$5,001 - $10,000':
          setDisplayFounderContactOption(true);
          break;
        case '$10,001 - $25,000':
          setDisplayFounderContactOption(true);
          break;
        case '$25,001 - $50,000':
          setDisplayFounderContactOption(true);
          break;
        case '$50,001 - $100,000':
          setDisplayFounderContactOption(true);
          break;
        case 'more than $100,000':
          setDisplayFounderContactOption(true);
          break;
        default:
          setDisplayFounderContactOption(false);
      }

      if (!phone) return { phone: 'Please enter a phone number' };
    }

    if (!amount) return { amount: 'Please select an amount' };
  };

  const onSubmit = values => {
    // Amount must be decimal for eq database
    let amountForApiBackup;
    const { amount } = values;
    if (isSyndicateDeal) {
      switch (amount) {
        case 'up to $5,000':
          amountForApiBackup = 5;
          break;
        case '$5,001 - $10,000':
          amountForApiBackup = 5001;
          break;
        case '$10,001 - $25,000':
          amountForApiBackup = 10001;
          break;
        case '$25,001 - $50,000':
          amountForApiBackup = 25001;
          break;
        case '$50,001 - $100,000':
          amountForApiBackup = 50001;
          break;
        case 'more than $100,000':
          amountForApiBackup = 100001;
          break;
        default:
          amountForApiBackup = 888;
      }
    } else {
      switch (amount) {
        case 'up to $1,000':
          amountForApiBackup = 1;
          break;
        case '$1,001 - $5,000':
          amountForApiBackup = 1001;
          break;
        case '$5,001 - $10,000':
          amountForApiBackup = 5001;
          break;
        case '$10,001 - $25,000':
          amountForApiBackup = 10001;
          break;
        case 'more than $25,000':
          amountForApiBackup = 25001;
          break;
        default:
          amountForApiBackup = 888;
      }
    }

    try {
      try {
        trackEoiSubscribed({
          firstName,
          lastName,
          email,
          subscribeToComms,
          offerName: companyName,
          uid,
          prismicId,
          countryOfResidence,
          hasTargetMax,
          isEoi,
          userId,
          phone: values?.phone,
          amount: amountForApiBackup,
        });
      } catch (error) {
        console.error('trackEoiSubscribed failed', error);
      }

      try {
        trackIntercomEvent({
          eventName: 'EOI Subscribed - Page 2',
          customAttributes: {
            telephone: values.phone,
          },
          metadata: {
            offerName: companyName,
            subscribeToSms: values.subscribeToSms,
            getContactedByFounders: displayFounderContactOption ? values.getContactedByFounders : '',
            intendedInvestment: values.amount,
            telephone: values?.phone,
            source: utmSource,
            medium: utmMedium,
            campaign: utmCampaign,
            raiseStatus,
            pageSource: 'Landing Page',
            landingPageUid: uid,
            isSyndicateDeal,
          },
        });
      } catch (error) {
        console.error('Intercom event EOI Subscribed - Page 2 failed', error);
      }

      try {
        trackAmplitudeEvent({
          email,
          eventName: 'EOI Subscribed - Page 2',
          eventData: {
            offerName: companyName,
            subscribeToSms: values.subscribeToSms,
            getContactedByFounders: displayFounderContactOption ? values.getContactedByFounders : '',
            intendedInvestment: values.amount,
            raiseStatus,
            pageSource: 'Landing Page',
            landingPageUid: uid,
            uid,
            isSyndicateDeal,
          },
          userData: {
            country: countryOfResidence || '',
            state: address?.state,
            email,
            subscribeToEmails: subscribeToComms,
            telephone: values.phone,
            firstName,
            lastName,
            sophisticatedStatus: profile?.sophisticatedStatus?.name,
            source: utmSource,
            medium: utmMedium,
            campaign: utmCampaign,
            identityVerifiedAt: user?.identityVerifiedAt,
          },
        });
      } catch (error) {
        console.error('Amplitude event EOI Subscribed - Page 2 failed', error);
      }

      try {
        trackOrtto({
          email,
          companyName,
          phone: values?.phone,
          subscribeToSms: Boolean(values?.subscribeToSms),
          allDealsSms: Boolean(values?.subscribeToSms),
          firstName,
          lastName,
          tags: [values.subscribeToSms ? `EOI SMS - ${companyName}` : '', `EOI PAGE 2 - ${companyName}`],
          eventName: 'eoisubscribedpg2',
          intendedInvestment: values.amount,
          utmSource,
          utmMedium,
          utmCampaign,
          uid,
          raiseStatus,
          getContactedByFounders: displayFounderContactOption ? values.getContactedByFounders : '',
          isSyndicateDeal,
        });
      } catch (error) {
        console.error('Ortto contact update - Page 2 failed', error);
      }

      if (isSyndicateDeal) {
        try {
          trackAmplitudeEvent({
            email,
            eventName: 'Sydicate Deal EOI Subscribed - Page 2',
            eventData: {
              offerName: companyName,
              subscribeToSms: values.subscribeToSms,
              getContactedByFounders: displayFounderContactOption ? values.getContactedByFounders : '',
              intendedInvestment: values.amount,
              raiseStatus,
              pageSource: 'Landing Page',
              landingPageUid: uid,
              uid,
              isSyndicateDeal,
            },
            userData: {
              country: countryOfResidence || '',
              state: address?.state,
              email,
              subscribeToEmails: subscribeToComms,
              telephone: values.phone,
              firstName,
              lastName,
              sophisticatedStatus: profile?.sophisticatedStatus?.name,
              source: utmSource,
              medium: utmMedium,
              campaign: utmCampaign,
              identityVerifiedAt: user?.identityVerifiedAt,
            },
          });
        } catch (error) {
          console.error('Amplitude event EOI Subscribed - Page 2 failed', error);
        }

        try {
          trackIntercomEvent({
            eventName: 'Sydicate Deal EOI Subscribed - Page 2',
            customAttributes: {
              telephone: values.phone,
            },
            metadata: {
              offerName: `${companyName} ${uid}`,
              subscribeToSms: values.subscribeToSms,
              getContactedByFounders: displayFounderContactOption ? values.getContactedByFounders : '',
              intendedInvestment: values.amount,
              telephone: values?.phone,
              source: utmSource,
              medium: utmMedium,
              campaign: utmCampaign,
              raiseStatus,
              pageSource: 'Landing Page',
              landingPageUid: uid,
              isSyndicateDeal,
            },
          });
        } catch (error) {
          console.error('Intercom event EOI Subscribed - Page 2 failed', error);
        }
      }
    } catch (eoiSubscribedModalError) {
      console.error('Event EOI subscribed - Page 2 failed', {
        error: eoiSubscribedModalError,
        offer: companyName,
      });
    }
    return setHasSubmitted(true);
  };

  return (
    <div className={styles.formTwoContainer}>
      {hasSubmitted ? (
        <>
          <h4
            className={styles.registerTitle}
          >{`Thanks ${firstName}, we have registered your interest in ${companyName}`}</h4>
          <div className={styles.eoiFormIcon}>
            <icon className={`${styles.icon} ${styles.iconEoiSuccess} icon icon-eoi-success`}></icon>
          </div>
          <div className={styles.modelSocialShare}>
            <SocialLinks
              theme="primary"
              socialLinks={links}
              event={companyName}
              userEmail={email}
              area="Register Interest Modal"
            />
          </div>
        </>
      ) : (
        <>
          <h4 className={styles.registerTitle}>We have registered your interest in this offer.</h4>
          <p className={styles.registerCopy}>Please complete the below so we can make sure you don’t miss out.</p>
          <FormikForm
            initialValues={{ subscribeToSms: true, getContactedByFounders: true, amount: '', phone: userPhone || '' }}
            onSubmit={onSubmit}
            validationFunction={validationFunction}
            submitButtonTheme="highlight"
            submitButtonText="Confirm Interest"
            className={styles.form}
            formikClassnames={formikClassnames}
            customStyle={customStyle}
          >
            {isSyndicateDeal && (
              <SelectInput
                formikClassnames={formikClassnames}
                className="amount"
                name="amount"
                label="intended investment amount"
              >
                <option value="" hidden aria-label="Blank first option to force user input" />
                <option value="up to $5,000">Up to $5,000</option>
                <option value="$5,001 - $10,000">$5,001 - $10,000</option>
                <option value="$10,001 - $25,000">$10,001 - $25,000</option>
                <option value="$25,001 - $50,000">$25,001 - $50,000</option>
                <option value="$50,001 - $100,000">$50,001 - $100,000</option>
                <option value="more than $100,000">More than $100,000</option>
              </SelectInput>
            )}
            {!isSyndicateDeal && (
              <SelectInput
                formikClassnames={formikClassnames}
                className="amount"
                name="amount"
                label="intended investment amount"
              >
                <option value="" hidden aria-label="Blank first option to force user input" />
                <option value="up to $1,000">Up to $1,000</option>
                <option value="$1,001 - $5,000">$1,001 - $5,000</option>
                <option value="$5,001 - $10,000">$5,001 - $10,000</option>
                <option value="$10,001 - $25,000">$10,001 - $25,000</option>
                <option value="more than $25,000">More than $25,000</option>
                <option value="unsure">Unsure</option>
              </SelectInput>
            )}
            <TempPhoneInput
              formikClassnames={formikClassnames}
              className="phone"
              name="phone"
              label="best contact number"
            />

            <CheckBoxSingleInput formikClassnames={formikClassnames} className="subscribeToSms" name="subscribeToSms">
              Equitise can contact me via phone/sms for this offer
            </CheckBoxSingleInput>

            {displayFounderContactOption && (
              <CheckBoxSingleInput
                formikClassnames={formikClassnames}
                className="subscribeToSms"
                name="getContactedByFounders"
              >
                I'm happy for {companyName} to contact me directly
              </CheckBoxSingleInput>
            )}
          </FormikForm>
          {registerInterestFooter?.html && (
            <div className={styles.modalFooterText}>
              <div dangerouslySetInnerHTML={{ __html: registerInterestFooter.html }} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const TargetMaxCopy = () => (
  <p>
    Unfortunately the target allocation has been met for this offer but if you submit your details below we will contact
    you in the event of additional fundraising
  </p>
);

const DealExpiredCopy = () => (
  <p>
    Unfortunately this offer has now closed but if you submit your details below we will contact you in the event of
    additional fundraising
  </p>
);

const RetailMaxCopy = () => (
  <>
    <p>
      Unfortunately we have reached our retail funding cap of $2,000,000 for this offer. Please register your interest
      below and if any allocation becomes available we will contact you.
    </p>
    <p>
      If you are a wholesale or qualified investor you can invest but please{' '}
      <Link to={DASHBOARD} className="retail-max-profile-link">
        update your profile
      </Link>{' '}
      OR contact the Equitise team at contact@equitise.com
    </p>
  </>
);
