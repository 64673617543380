exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-holding-js": () => import("./../../../src/pages/holding.js" /* webpackChunkName: "component---src-pages-holding-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-unpublished-preview-js": () => import("./../../../src/pages/unpublishedPreview.js" /* webpackChunkName: "component---src-pages-unpublished-preview-js" */),
  "component---src-templates-blog-landing-page-jsx": () => import("./../../../src/templates/blogLandingPage.jsx" /* webpackChunkName: "component---src-templates-blog-landing-page-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-category-page-jsx": () => import("./../../../src/templates/categoryPage.jsx" /* webpackChunkName: "component---src-templates-category-page-jsx" */),
  "component---src-templates-deal-room-jsx": () => import("./../../../src/templates/dealRoom.jsx" /* webpackChunkName: "component---src-templates-deal-room-jsx" */),
  "component---src-templates-invest-page-jsx": () => import("./../../../src/templates/investPage.jsx" /* webpackChunkName: "component---src-templates-invest-page-jsx" */),
  "component---src-templates-landing-page-jsx": () => import("./../../../src/templates/landingPage.jsx" /* webpackChunkName: "component---src-templates-landing-page-jsx" */),
  "component---src-templates-legal-page-jsx": () => import("./../../../src/templates/legalPage.jsx" /* webpackChunkName: "component---src-templates-legal-page-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-search-page-jsx": () => import("./../../../src/templates/searchPage.jsx" /* webpackChunkName: "component---src-templates-search-page-jsx" */),
  "component---src-templates-team-page-jsx": () => import("./../../../src/templates/teamPage.jsx" /* webpackChunkName: "component---src-templates-team-page-jsx" */)
}

